import { useContext, useState } from "react";
import { GiscModal } from "../Modal"
import { ToastAlert } from "../ToastAlert";
import { AddPlanInvatamant } from "../../api/ApiService";
import ComboBox from "../Combobox";
import { PlanContext } from "../../store/plan-context";
import { Specializare } from "../../models/specializare.model";
import { Domeniu } from "../../models/domeniu.model";

interface AddPlanModalProps {
    addPlanModalOpen: boolean;
    onSuccessSave: () => void;
    onClose: () => void;
}

export const AddPlanModal = (props: AddPlanModalProps) => {
    const { specializari, domenii } = useContext(PlanContext);

    const [domeniu, setDomeniu] = useState<Domeniu>({ id: "", numeDl: "" });
    const [specializare, setSpecializare] = useState<Specializare>({ id: "", nume: "" });
    const [anInceput, setAnInceput] = useState(0);
    const [anFinal, setAnFinal] = useState(0);
    const [nrCredite, setNrCredite] = useState(0);
    const [durataSemestru, setDurataSemestru] = useState(0);
    const [durataSesiuneIarna, setDurataSesiuneIarna] = useState(0);
    const [durataRestanteIarna, setDurataRestanteIarna] = useState(0);
    const [durataSesiuneVara, setDurataSesiuneVara] = useState(0);
    const [durataRestanteVara, setDurataRestanteVara] = useState(0);
    const [durataRestanteToamna, setDurataRestanteToamna] = useState(0);
    const [nume, setNume] = useState("");
    const [toastInfo, setToastInfo] = useState<{ message: string, show: boolean, type: "success" | "error" | "warning" | "info" }>({ message: '', type: 'info', show: false });

    const domeniiSelectOptions = domenii.filter(e => specializare.id === "" || e.id === specializare.domId).map(d => ({ label: d.numeDl, value: d.id }));
    const specializariSelectOptions = specializari.filter(e => domeniu.id === "" || e.domId === domeniu.id).map(d => ({ label: d.nume, value: d.id }));

    const onAddPlan = () => {
        if (!domeniu.id) {
            setToastInfo({ message: 'Selectează un domeniu!', type: 'error', show: true });
            return;
        }
        if (!specializare.id) {
            setToastInfo({ message: 'Selectează o specializare!', type: 'error', show: true });
            return;
        }
        if (!anInceput || !anFinal) {
            setToastInfo({ message: 'Completează anul de început și final!', type: 'error', show: true });
            return;
        }

        if (anInceput > anFinal) {
            setToastInfo({ message: 'Anul de început e mai mare decât cel final!', type: 'error', show: true });
            return;
        }
        if (!nume) {
            setToastInfo({ message: 'Completează numele planului!', type: 'error', show: true });
            return;
        }
        if (!nrCredite) {
            setToastInfo({ message: 'Completează numărul de credite!', type: 'error', show: true });
            return;
        }
        if (!durataSemestru) {
            setToastInfo({ message: 'Completează durata semestrului!', type: 'error', show: true });
            return;
        }
        if (durataRestanteIarna < 0 || durataRestanteVara < 0 || durataRestanteToamna < 0 || durataSesiuneIarna < 0 || durataSesiuneVara < 0 || durataSemestru < 0) {
            setToastInfo({ message: 'Valorile nu pot fi mai mici ca zero!', type: 'error', show: true });
            return;
        }
        AddPlanInvatamant({
            domId: domeniu?.id, specId: specializare?.id, anInceput, anFinal, nume, validated: "Nevalidat",
            nrCredite, durataSemestru, durataSesiuneIarna, durataRestanteIarna, durataSesiuneVara, durataRestanteVara, durataRestanteToamna
        })
            .then(() => {
                setToastInfo({ message: 'Plan adăugat cu succes', type: 'success', show: true });
                props.onSuccessSave();
                props.onClose();
            })
            .catch(() => {
                setToastInfo({ message: 'Ceva nu a mers bine', type: 'error', show: true });
            });
    }

    return <><GiscModal isOpen={props.addPlanModalOpen} title="Adaugă plan de învațământ" onClose={() => props.onClose()} onConfirmClick={() => onAddPlan()} confirmButtonTitle="Adaugă plan">
        <form>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                    {/* replace here with dropdown */}
                    <label className="block mb-2 text-sm font-medium text-gray-900">Domeniu</label>
                    <div className="">
                        <ComboBox options={[{label: "", value: ""}, ...domeniiSelectOptions]}
                                  value={{label: domeniu.numeDl, value: domeniu.id || ""}}
                                  onChange={v => setDomeniu(domenii.find(d => d.id === v?.value) || {
                                      id: "",
                                      numeDl: ""
                                  })}/>
                    </div>
                </div>
                <div>
                    {/* replace here with dropdown */}
                    <label className="block mb-2 text-sm font-medium text-gray-900">Specializare</label>
                    <div className="">
                        <ComboBox options={[{label: "", value: ""}, ...specializariSelectOptions]}
                                  value={{label: specializare.nume, value: specializare.id || ""}}
                                  onChange={v => setSpecializare(specializari.find(d => d.id === v?.value) || {
                                      id: "",
                                      nume: ""
                                  })}/>
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">An început</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="2024"
                           required
                           onChange={e => setAnInceput(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">An final</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           required
                           placeholder="2028" onChange={e => setAnFinal(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Nume</label>
                    <input type="text"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           required
                           onChange={e => setNume(e.target.value)}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Număr credite</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           required
                           placeholder="240" onChange={e => setNrCredite(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată semestru (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           required
                           placeholder="14" onChange={e => setDurataSemestru(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată sesiune iarnă
                        (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="3" onChange={e => setDurataSesiuneIarna(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată restanțe iarnă
                        (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="1" onChange={e => setDurataRestanteIarna(Number(e.target.value))}/>
                </div>

                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată sesiune vară
                        (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="3" onChange={e => setDurataSesiuneVara(Number(e.target.value))}/>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată restanțe vară
                        (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="1" onChange={e => setDurataRestanteVara(Number(e.target.value))}/>
                </div>

                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Durată restanțe toamnă
                        (săptămâni)</label>
                    <input type="number"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                           placeholder="2" onChange={e => setDurataRestanteToamna(Number(e.target.value))}/>
                </div>
            </div>
        </form>
    </GiscModal>
        {toastInfo.show ? <ToastAlert message={toastInfo.message} type={toastInfo.type}
                                      onClose={() => setToastInfo({...toastInfo, show: false})}/> : <></>}

    </>
}
