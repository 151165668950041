import {PlanInvatamantTableData, PlanTableType} from "../../models/plan-invatamant-table-data.model";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";
import { getCriteriu } from "../../services/utils.service";
import { GenericReadonlyTable, GenericTableProps } from "../GenericReadonlyTable";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React from "react";

export interface PlanSummaryProps {
    planData: PlanInvatamantTableData;
}

const ani = [
    'I', 'II', 'III', 'IV', 'V', 'VI'
];

const ORE_CREDIT_DEFAULT = 25;

export const PlanSummary = (props: PlanSummaryProps) => {

    const planSummary: PlanSummaryEntryModel[] = props.planData.summary;
    // console.log(planSummary);
    const crediteTotal = planSummary.find(e => e.slug === "credite-total");
    const crediteObligatoriiTotal = planSummary.find(e => e.slug === "credite-obligatorii-total");

    const crediteDisciplineOptionale = planSummary.find(e => e.slug === "credite-optionale-total");

    const creditePractica = planSummary.find(e => e.slug === "credite-practica-total");

    const crediteElaborareProiectDiploma = planSummary.find(e => e.slug === "credite-elaborare-proiect-diploma-total");

    const crediteExamenDiploma = planSummary.find(e => e.slug === "credite-examen-diploma-total");

    const crediteEducatieFizica = planSummary.find(e => e.slug === "credite-educatie-fizica-total");

    // credite
    const crediteTable: GenericTableProps = {
        headers: [
            { label: 'Credite', key: 'credite' },
            { label: 'Număr credite', key: 'numar' },
            { label: 'Criterii', key: 'criteriu' }],
        data: [
            { credite: 'Numărul de credite alocate conform legislației', numar: crediteTotal?.planValue, criteriu: '240 (exclusiv creditele pentru promovarea examenului de diplomă)', error: crediteTotal?.error },
            { credite: 'Credite pentru disciplinele obligatorii', numar: crediteObligatoriiTotal?.planValue, criteriu: '', error: crediteObligatoriiTotal?.error },
            { credite: 'Credite pentru disciplinele opționale', numar: crediteDisciplineOptionale?.planValue, criteriu: getCriteriu(crediteDisciplineOptionale) },
            { credite: 'Credite la practică (în cadrul disciplinelor obligatorii)', numar: creditePractica?.planValue, criteriu: getCriteriu(creditePractica), error: creditePractica?.error },
            { credite: 'Credite pentru elaborarea proiectului de diplomă', numar: crediteElaborareProiectDiploma?.planValue, criteriu: getCriteriu(crediteElaborareProiectDiploma), error: crediteElaborareProiectDiploma?.error },
            { credite: 'Credite pentru promovarea examenului de diplomă', numar: crediteExamenDiploma?.planValue, criteriu: getCriteriu(crediteExamenDiploma), error: crediteExamenDiploma?.error },
            { credite: 'Credite pentru disciplinele de Educație Fizică', numar: crediteEducatieFizica?.planValue, criteriu: getCriteriu(crediteEducatieFizica), error: crediteEducatieFizica?.error },
        ]
    }

    const creditePeSemestru: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }, { label: 'Total', key: 'total' }],
        data: []
    }

    props.planData.years.forEach((year, yearIdx) => {
        const crediteSem1 = (year.semesters[0].summary.find(e => e.slug === "credite-semestru")?.planValue || 0);
        const crediteSem2 = (year.semesters[1].summary.find(e => e.slug === "credite-semestru")?.planValue || 0);
        creditePeSemestru.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': crediteSem1, 'sem-2': crediteSem2, total: crediteSem1 + crediteSem2 });
    })

    const echivalentaOreCredit = planSummary.find(e => e.slug === "echivalenta-ore-credit");
    console.log(echivalentaOreCredit);
    const raportOreCredit: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }],
        data: []
    }

    const oreCreditPeSemestru: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }],
        data: []
    }

    props.planData.years.forEach((year, yearIdx) => {
        const oreCreditSem1 = (year.semesters[0].summary.find(e => e.slug === "echivalenta-ore-credit")?.planValue || 0);
        const oreCreditSem2 = (year.semesters[1].summary.find(e => e.slug === "echivalenta-ore-credit")?.planValue || 0);

        var raportOreCreditSem1 = oreCreditSem1 == (echivalentaOreCredit?.valoare || ORE_CREDIT_DEFAULT) ? 'DA' : 'NU';
        var raportOreCreditSem2 = oreCreditSem2 == (echivalentaOreCredit?.valoare || ORE_CREDIT_DEFAULT) ? 'DA' : 'NU';

        raportOreCredit.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': raportOreCreditSem1, 'sem-2': raportOreCreditSem2 });
        oreCreditPeSemestru.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': oreCreditSem1, 'sem-2': oreCreditSem2 });
    })




    // end credite

    // structura an
    const durataSemestru = planSummary.find(e => e.slug === "durata-semestru");
    const durataSesiuneIarna = planSummary.find(e => e.slug === "durata-sesiune-iarna");
    const durataRestanteIarna = planSummary.find(e => e.slug === "durata-restante-iarna");
    const durataSesiuneVara = planSummary.find(e => e.slug === "durata-sesiune-vara");
    const durataRestanteVara = planSummary.find(e => e.slug === "durata-restante-vara");
    const durataRestanteToamna = planSummary.find(e => e.slug === "durata-restante-toamna");

    const structuraAnUniversitar: GenericTableProps = {
        headers: [
            { label: 'Anul', key: 'an' },
            {
                label: 'Activități didactice', key: '',
                subHeaders: [
                    { label: 'sem. I', key: 'sem-1' },
                    { label: 'sem. 2', key: 'sem-2' }
                ]
            },
            {
                label: 'Sesiuni', key: '',
                subHeaders: [
                    { label: 'Iarnă', key: 'iarna' },
                    { label: 'Restanțe iarnă', key: 'restante-iarna' },
                    { label: 'Vară', key: 'vara' },
                    { label: 'Restanță vară', key: 'restante-vara' },
                    { label: 'Restanță Toamnă', key: 'restante-toamna' },
                ]
            },
            { label: 'Practică*', key: 'practica' },
        ],
        data: [
            // { an: 'Anul I', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: '' },
            // { an: 'Anul II', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: '' },
            // { an: 'Anul III', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: 6 },
            // { an: 'Anul IV', 'sem-1': 14, 'sem-2': 7, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': '', practica: '' },
        ]
    }

    props.planData.years.forEach((year, yearIdx) => {
        structuraAnUniversitar.data.push({
            'an': `Anul ${ani[yearIdx]}`,
            'sem-1': durataSemestru?.planValue,
            'sem-2': durataSemestru?.planValue,
            'iarna': durataSesiuneIarna?.planValue,
            'restante-iarna': durataRestanteIarna?.planValue,
            'vara': durataSesiuneVara?.planValue,
            'restante-vara': durataRestanteVara?.planValue,
            'restante-toamna': durataRestanteToamna?.planValue,
            'practica': ''
        });
    })
    structuraAnUniversitar.data[props.planData.years.length - 1]['sem-2'] = 7;
    structuraAnUniversitar.data[props.planData.years.length - 1]['restante-toamna'] = '';

    // end structura an


    // examinare

    const disciplineTotal = planSummary.find(e => e.slug === "discipline-total");

    const evaluariExamenTotal = planSummary.find(e => e.slug === "evaluari-examen-total");
    const pondereEvaluariExamenTotal = planSummary.find(e => e.slug === "pondere-evaluari-examen-total");

    const evaluariDistribuitaTotal = planSummary.find(e => e.slug === "evaluari-distribuita-total");
    const pondereEvaluariDistribuitaTotal = planSummary.find(e => e.slug === "pondere-evaluari-distribuita-total");

    const evaluariColocviuTotal = planSummary.find(e => e.slug === "evaluari-colocviu-total");
    const pondereEvaluariColocviuTotal = planSummary.find(e => e.slug === "pondere-evaluari-colocviu-total");

    const examinareTable: GenericTableProps = {
        headers: [
            { label: 'Examinare', key: 'examinare' },
            { label: 'Număr', key: 'numar' },
            { label: 'Procent', key: 'procent' },
            { label: 'Criterii', key: 'criteriu' }
        ],
        data: [
            { examinare: 'Nr. total discipline obligatorii', numar: disciplineTotal?.planValue, procent: `100%`, criteriu: getCriteriu(disciplineTotal), error: disciplineTotal?.error },
            { examinare: 'Nr. discipline finalizate cu Examen', numar: evaluariExamenTotal?.planValue, procent: `${pondereEvaluariExamenTotal?.planValue}%`, criteriu: getCriteriu(pondereEvaluariExamenTotal), error: pondereEvaluariExamenTotal?.error },
            { examinare: 'Nr. discipline finalizate cu Evaluare Distribuită', numar: evaluariDistribuitaTotal?.planValue, procent: `${pondereEvaluariDistribuitaTotal?.planValue}%`, criteriu: getCriteriu(pondereEvaluariDistribuitaTotal), error: pondereEvaluariDistribuitaTotal?.error },
            { examinare: 'Nr. discipline finalizate cu Colocviu', numar: evaluariColocviuTotal?.planValue, procent: `${pondereEvaluariColocviuTotal?.planValue}%`, criteriu: getCriteriu(pondereEvaluariColocviuTotal), error: pondereEvaluariColocviuTotal?.error },
        ]
    }

    // end examinare

    // ponderi
    // console.log(planSummary);
    const oreTotal = planSummary.find(e => e.slug === "ore-total");
    const oreImpuseTotal = planSummary.find(e => e.slug === "ore-discipline-impuse-total");
    const pondereOreImpuseTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-impuse-total");

    const oreOptionaleTotal = planSummary.find(e => e.slug === "ore-discipline-optionale-total");
    const pondereOreOptionaleTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-optionale-total");

    //-
    const oreDisciplineFundamentaleTotal = planSummary.find(e => e.slug === "ore-discipline-fundamentale-total");
    const pondereOreDisciplineFundamentaleTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-fundamentale-total");

    const oreDisciplineDomeniuTotal = planSummary.find(e => e.slug === "ore-discipline-domeniu-total");
    const pondereOreDisciplineDomeniuTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-domeniu-total");

    const oreDisciplineSpecialitateTotal = planSummary.find(e => e.slug === "ore-discipline-specialitate-total");
    const pondereOreDisciplineSpecialitateTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-specialitate-total");

    const oreDisciplineComplementareTotal = planSummary.find(e => e.slug === "ore-discipline-complementare-total");
    const pondereOreDisciplineComplementareTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-complementare-total");

    const oreDisciplineFacultativeTotal = planSummary.find(e => e.slug === "ore-discipline-facultative-total");
    const pondereOreDisciplineFacultativeTotal = planSummary.find(e => e.slug === "pondere-ore-discipline-facultative-total");

    //-
    const raportOreCursAplicatii = planSummary.find(e => e.slug === "raport-ore-curs-aplicatii");

    const ponderiTable: GenericTableProps = {
        headers: [
            { label: 'Pondere', key: 'pondere' },
            { label: 'Număr ore', key: 'ore' },
            { label: 'Procent', key: 'procent' },
            { label: 'Criterii', key: 'criteriu' }
        ],
        data: [
            { pondere: 'Număr total de ore obligatorii (impuse+opționale)', ore: `${oreTotal?.planValue || 0}`, procent: '100%', criteriu: getCriteriu(oreTotal), error: oreTotal?.error },
            { pondere: 'Discipline impuse', ore: `${oreImpuseTotal?.planValue || 0}`, procent: `${pondereOreImpuseTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreImpuseTotal), error: pondereOreImpuseTotal?.error },
            { pondere: 'Discipline opționale', ore: `${oreOptionaleTotal?.planValue || 0}`, procent: `${pondereOreOptionaleTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreOptionaleTotal), error: pondereOreOptionaleTotal?.error },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Discipline fundamentale (DF)', ore: `${oreDisciplineFundamentaleTotal?.planValue || 0}`, procent: `${pondereOreDisciplineFundamentaleTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreDisciplineFundamentaleTotal), error: pondereOreDisciplineFundamentaleTotal?.error },
            { pondere: 'Discipline în domeniu (DD)', ore: `${oreDisciplineDomeniuTotal?.planValue || 0}`, procent: `${pondereOreDisciplineDomeniuTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreDisciplineDomeniuTotal), error: pondereOreDisciplineDomeniuTotal?.error },
            { pondere: 'Discipline de specialitate (DS)', ore: `${oreDisciplineSpecialitateTotal?.planValue || 0}`, procent: `${pondereOreDisciplineSpecialitateTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreDisciplineSpecialitateTotal), error: pondereOreDisciplineSpecialitateTotal?.error },
            { pondere: 'Discipline complementare (DC)', ore: `${oreDisciplineComplementareTotal?.planValue || 0}`, procent: `${pondereOreDisciplineComplementareTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreDisciplineComplementareTotal), error: pondereOreDisciplineComplementareTotal?.error },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Discipline facultative', ore: `${oreDisciplineFacultativeTotal?.planValue || 0}`, procent: `${pondereOreDisciplineFacultativeTotal?.planValue || 0}%`, criteriu: getCriteriu(pondereOreDisciplineFacultativeTotal), error: pondereOreDisciplineFacultativeTotal?.error },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Raport ore curs / ore aplicații', ore: `${raportOreCursAplicatii?.planValue || 0}`, procent: ``, criteriu: getCriteriu(raportOreCursAplicatii), error: raportOreCursAplicatii?.error },
        ]

    }

    // end ponderi

    // distributie ore

    const distributieOreTable: GenericTableProps = {
        headers: [
            { label: 'Anul', key: 'an' },
            {
                label: 'ore / săptămână',
                key: 'ore-saptamana',
                subHeaders: [
                    { label: 'sem. I', key: 'sem-1' },
                    { label: 'sem. 2', key: 'sem-2' }
                ]
            },
            { label: 'Criterii', key: 'criteriu' },

        ],
        data: []
    }

    props.planData.years.forEach((year, yearIdx) => {
        const oreSem1 = year.semesters[0].summary.find(e => e.slug === "ore-saptamana");
        const oreSem1Value = (oreSem1?.planValue || 0);
        const oreSem1Error = oreSem1?.error;

        const oreSem2 = year.semesters[1].summary.find(e => e.slug === "ore-saptamana");
        const oreSem2Value = (oreSem2?.planValue || 0);
        const oreSem2Error = oreSem2?.error;

        // const error = oreSem1Error || oreSem2Error ? "Numarul de ore nu se afla in interval" : "";
        const error = oreSem1Error || oreSem2Error ? oreSem1Error + " " + oreSem2Error : "";
        distributieOreTable.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': oreSem1Value, 'sem-2': oreSem2Value, criteriu: getCriteriu(oreSem1), error: error });
    })

    // end distributie ore

    // distributie ore

    const criteriiFinalizareTable: GenericTableProps = {
        headers: [
            { label: 'Criteriu', key: 'criteriu' },
        ],
        data: [
            { criteriu: '1. Comunicarea temei proiectului de diplomă - semestrul VII' },
            { criteriu: '2. Elaborarea proiectului de diplomă - semestrul VIII' },
            { criteriu: '3. Susținerea proiectului de diplomă: sesiuni iunie, septembrie, februarie' },
        ]
    }

    // end distributie ore
    const orePracticaTotal = planSummary.find(e => e.slug === "ore-practica-total");

    const errorAccordionColor = "#ef9a9a";

    return <section className="w-full flex flex-col mt-5">
        <h1 className="text-3xl font-semibold upt-text-dark my-5">Rezumat plan învățământ</h1>

        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                sx={{
                    backgroundColor: crediteTable.data.some(x => x.error) || creditePeSemestru.data.some(x => x.error)
                    || raportOreCredit.data.some(x => x.error) || oreCreditPeSemestru.data.some(x => x.error) ? errorAccordionColor : ""
                }}
                aria-controls="panel4-content"

                id="panel4-header"
            >

                    <h1 className="text-2xl font-semibold upt-text-dark">I. Credite</h1>
            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col mb-5">
                    <div className="flex flex-wrap justify-around">
                        <div>
                            <GenericReadonlyTable headers={crediteTable.headers} data={crediteTable.data}/>
                            <p>Distribuția numărului de credite pe semestre:</p>
                            <p className="text-xs mb-2">(exclusiv creditele pentru promovarea examenului de
                                diplomă)</p>
                            <GenericReadonlyTable headers={creditePeSemestru.headers} data={creditePeSemestru.data}/>
                        </div>
                        <div>
                            <p className="mb-2">Au toate
                                disciplinele {(echivalentaOreCredit?.valoare || ORE_CREDIT_DEFAULT)} ore/credit?</p>
                            <GenericReadonlyTable headers={raportOreCredit.headers} data={raportOreCredit.data}/>
                        </div>
                        <div>
                            <p className="mb-2">Numar ore/credit</p>
                            <GenericReadonlyTable headers={oreCreditPeSemestru.headers}
                                                  data={oreCreditPeSemestru.data}/>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                aria-controls="panel4-content"
                id="panel4-header"
                sx={{
                    backgroundColor: creditePeSemestru.data.some(x => x.error) || raportOreCredit.data.some(x => x.error)
                    || oreCreditPeSemestru.data.some(x => x.error) || structuraAnUniversitar.data.some(x => x.error) ? errorAccordionColor : ""
                }}
            >

                <h1 className="text-2xl font-semibold upt-text-dark">II. Structura Anului Universitar (în nr.
                    săptămâni)</h1>
            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col mb-5">
                    <div className="flex flex-wrap justify-around">
                        <div>
                            <p>Distribuția numărului de credite pe semestre:</p>
                            <p className="text-xs mb-2">(exclusiv creditele pentru promovarea examenului de
                                diplomă)</p>
                            <GenericReadonlyTable headers={creditePeSemestru.headers} data={creditePeSemestru.data}/>
                        </div>
                        <div>
                            <p className="mb-2">Au toate
                                disciplinele {(echivalentaOreCredit?.valoare || ORE_CREDIT_DEFAULT)} ore/credit?</p>
                            <GenericReadonlyTable headers={raportOreCredit.headers} data={raportOreCredit.data}/>
                        </div>
                        <div>
                            <p className="mb-2">Numar ore/credit</p>
                            <GenericReadonlyTable headers={oreCreditPeSemestru.headers}
                                                  data={oreCreditPeSemestru.data}/>
                        </div>
                        <div className="flex flex-col my-5">

                            <GenericReadonlyTable headers={structuraAnUniversitar.headers}
                                                  data={structuraAnUniversitar.data}/>
                            <p className="mt-3">* Practica se elaborează pe baza unor programe elaborate în departamente
                                și aprobate de
                                Consiliul Facultății. Practica se desfășoară în cadrul facultății sau în unități
                                economice de profil, pe baza unor convenții de practică. În semestrul VIII cele două
                                săptămâni de
                                practică sunt distribuite în timpul întregului semestru.</p>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>


        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                aria-controls="panel4-content"
                id="panel4-header"
                sx={{
                    backgroundColor: examinareTable.data.some(x => x.error) ? errorAccordionColor : ""
                }}
            >

                <h1 className="text-2xl font-semibold upt-text-dark">III. Examinare</h1>

            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col my-5">
                    <GenericReadonlyTable headers={examinareTable.headers} data={examinareTable.data}/>
                </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                aria-controls="panel4-content"
                id="panel4-header"
                sx={{
                    backgroundColor: ponderiTable.data.some(x => x.error) ? errorAccordionColor : ""
                }}
            >

                <h1 className="text-2xl font-semibold upt-text-dark">IV. Ponderile disciplinelor</h1>

            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col my-5">
                    <GenericReadonlyTable headers={ponderiTable.headers} data={ponderiTable.data}/>
                </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                aria-controls="panel4-content"
                id="panel4-header"
                sx={{
                    backgroundColor: distributieOreTable.data.some(x => x.error) ? errorAccordionColor : ""
                }}

            >

                <h1 className="text-2xl font-semibold upt-text-dark">V. Distribuția orelor la disciplinele obligatorii
                    pe
                    săptămână</h1>

            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col my-5">

                    <GenericReadonlyTable headers={distributieOreTable.headers} data={distributieOreTable.data}/>
                    <p>Număr total ore practică: <span>{orePracticaTotal?.planValue || 0} ore</span>
                        <span>({getCriteriu(orePracticaTotal)})</span></p>
                </div>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<div>&#9660;</div>}
                aria-controls="panel4-content"
                id="panel4-header"
                sx={{
                    backgroundColor: criteriiFinalizareTable.data.some(x => x.error) ? "#ef9a9a" : ""
                }}
            >

                <h1 className="text-2xl font-semibold upt-text-dark">VI. Examenul de finalizare a studiilor</h1>

            </AccordionSummary>
            <AccordionDetails>
                <div className="flex flex-col my-5">

                <GenericReadonlyTable headers={criteriiFinalizareTable.headers} data={criteriiFinalizareTable.data}/>
            </div>
        </AccordionDetails>
    </Accordion>


</section>
}
